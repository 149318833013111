<template>
<div>
  <div v-if="deal" class="container mx-auto mt-10 lg:px-3 px-5">
    <div class="w-full flex flex-row justify-between">
      <div class="flex flex-row items-stretch flex-grow">
        <img class="h-32" :src="deal.image" />
        <div class="flex flex-col ml-7">
          <p class="text-lg font-semibold">{{ deal.title }}</p>
          <ul class="flex flex-row list-none text-black-lighter text-sm my-2">
            <li class="mr-8 flex flex-row items-center">
              <i
                class="rounded-full w-2 h-2 mr-2 bg-black-lighter inline-block"
              ></i
              >{{ deal.type }}
            </li>
            <li class="mr-8 flex flex-row items-center">
              <i
                class="rounded-full w-2 h-2 mr-2 bg-black-lighter inline-block"
              ></i
              >{{ deal.miles }} Miles
            </li>
          </ul>
<!--          <p class="text-sm text-black-lighter">-->
<!--            Current Purchase Price <b>${{ deal.currentBid.toLocaleString() }} USD</b>-->
<!--          </p>-->
        </div>
      </div>
      <div class="flex flex-row h-32">
        <div
          class="flex flex-col justify-center items-center border p-8 w-36 mr-8"
        >
          <p class="text-black-lighter text-sm text-center">Purchase Price</p>
          <p class="text-black-light text-lg mt-2 font-semibold">
            ${{ bidValue.toLocaleString() }}
          </p>
        </div>

        <div
          class="
            flex flex-col
            justify-center
            items-center
            p-4
            w-36
            bg-gray-light
          "
        >
          <p class="text-black-lighter text-sm text-center">10% of Your Purchase Price</p>
          <p class="text-black-light text-lg mt-2 font-semibold">
            ${{ bidBookValue() }}
          </p>
        </div>
      </div>
    </div>
    <div class="w-full mb-10">
      <p class="text-black-lighter text-xs my-8 leading-5">
        To reserve the car, we put a hold on your credit card for 10% of the purchase price or down payment.  We will let you know the status of your purchase within the next 24 to 48 hours. If we are able to procure the car from our wholesale partner we ask you to wire the down payment and thereafter we release your credit card hold and start the shipping process. If we are unsuccessful in procuring the car from our wholesale partners we release the credit card hold immediately.
      </p>
      <p class="text-black-lighter text-xs my-8 leading-5">
        Please email us if you have any questions or concerns regarding your credit card hold @ <a class="underline text-blue" href="mailto:admin@fairpy.com">admin@fairpy.com </a> or call us @ <a class="underline text-blue" href="tel:817-357-8462">817-357-8462</a>
      </p>
      <hr />
    </div>

    <div class="w-full flex flex-row mt-6 mb-16">
      <div class="w-7/12 items-stretch flex pr-8">
        <div class="border flex-grow p-6">
          <CreditCardForm />
        </div>
      </div>
      <div class="w-5/12 items-stretch flex pl-8">
        <div class="border flex-grow p-6 flex flex-col">
          <h3 class="text-xl font-semibold mb-14">Summary</h3>
          <div class="flex flex-col w-full text-black-lighter flex-grow">
            <div class="flex justify-between items-center text-sm mb-8">
              <p>
                10% of Purchase Price (Your Purchase Price: ${{ bidValue.toLocaleString() }})
              </p>
              <p class="font-semibold text-black-light">
                ${{ bidBookValue() }}
              </p>
            </div>
            <div class="flex justify-between items-center text-sm mb-8">
              <p class="text-green-700">Promo Code Applied</p>
              <p class="font-semibold text-green-700">- ${{this.discountAmount }}</p>
            </div>
            <div class="flex justify-between items-center text-sm mb-8">
              <p>Other Charges</p>
              <p class="font-semibold text-black-light">$0.00</p>
            </div>
            <hr />
            <div class="flex justify-between items-center text-sm mb-8 mt-8">
              <p class="text-black-light">Total Hold Amount</p>
              <p class="font-semibold text-black-light text-2xl">
                ${{ this.afterDiscountAmount }}
              </p>
            </div>
            <div class="flex flex-col items-center mb-6 flex-grow justify-end">
              <button
                @click.prevent="pay"
                class="bg-blue uppercase text-white p-4 w-full lg:block hidden"
              >
                PAY NOW AND BLOCK
              </button>
              <a
                @click.prevent="gotoDetails"
                class="mt-6 cursor-pointer text-sm text-black-lighter"
                href=""
              >
                Go Back
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :showModalBg="true"
    bodyClass="lg:w-md-modal w-screen lg:h-auto justify-center m-auto p-5 min-h-screen lg:min-h-0"
    v-show="isThankYouModalVisible"
    @close="closeModalAndGotoHome"
  >
    <template v-slot:header>
      <div></div>
    </template>

    <template v-slot:body>
      <div class="flex justify-center relative w-full">
        <div
          class="
            w-full
            flex flex-col
            justify-center
            items-center
            text-center
            lg:px-12
            py-12
            px-6
            relative
          "
        >
          <div class="lg:w-2/3 w-10/12 flex flex-col items-center mt-8">
            <img class="lg:w-36" src="/images/thankyou-modal-icon.png" />
          </div>
          <h1
            class="
              text-3xl
              font-display
              mt-8
              mb-6
              text-center
              lg:px-12 lg:mx-12
              px-4
              mx-4
            "
          >
            Thank You
          </h1>
          <p class="text-sm text-gray-medium lg:px-4 px-0 mb-6">
            Your Bid has been Placed Successfully.
          </p>
          <button
            @click.prevent="closeModalAndGotoHome"
            class="bg-blue uppercase text-white p-3 px-6 block"
          >
            Continue
          </button>
        </div>
      </div>
    </template>
  </Modal>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";
import CreditCardForm from "../components/CreditCardForm.vue";
import Modal from "../components/Modal.vue";

export default {
  data() {
    return {
      toggle: screen.width > 768,
      token: null,
      stripe: null,
      discountAmount: '',
      afterDiscountAmount: '',
      isThankYouModalVisible: false,
    };
  },
  components: {
    CreditCardForm,
    Modal,
  },
  computed: {
    ...mapGetters({
      deal: "selectedDeal",
      bidValue: "bidValue",
    }),
    // stripeElements() {
    //   return this.$stripe.elements();
    // },
  },
  setup() {
    const toast = useToast();
    return {
      toast,
      details: ''
    };
  },
  watch: {
    deal(value) {
      console.log(value);
     return  this.details = value;
    },
    bidValue(value) {
      console.log(value, "----------*******");
    },
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.getPromoCode();
  },
  methods: {
    getPromoCode(){
      const promoInfo = {
        customer_id: this.$store.state.auth.user.id,
        vin: this.deal.id,
      };
      console.log(promoInfo);
      this.$store.dispatch("getPromoCode", promoInfo).then(
          (value) => {
            console.log('get response -----');
            console.log(this.bidValue * 0.1);
            let tenPercentage = Math.round(this.bidValue * 0.1)
            this.discountAmount = value.data.data.amount;
            this.afterDiscountAmount = tenPercentage - value.data.data.amount;
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },
    bidBookValue() {
      const value = Math.round(this.bidValue * 0.1);
      return value.toLocaleString();
    },
    myEventHandler() {
      this.toggle = screen.width > 768;
    },
    gotoDetails() {
      this.$router.back();
    },
    showThankYouModal() {
      this.isThankYouModalVisible = true;
      document.body.classList.add("overflow-hidden");
      // this.updateBidToServer();
    },
    closeModal() {
      this.isThankYouModalVisible = false;
      document.body.classList.remove("overflow-hidden");
    },
    closeModalAndGotoHome() {
      this.closeModal();
      this.$router.push({
        name: "Home",
      });
    },
    async pay() {
      console.log(this.$store.state.card.cardNumber);
      console.log(this.$store.state.card.paymentIntent);

      this.$store.dispatch("setIsLoading", true);

      let name = this.$store.state.auth.user.first_name;
      name += " ";
      name += this.$store.state.auth.user.last_name;

      this.$store.state.card.stripe
        .confirmCardPayment(
          this.$store.state.card.paymentIntent.client_secret,
          {
            payment_method: {
              card: this.$store.state.card.cardNumber,
              billing_details: {
                name: name,
              },
            },
          }
        )
        .then((result) => {
          this.$store.dispatch("setIsLoading", false);
          if (result.error) {
            // Show error to your customer (for example, insufficient funds)
            console.log(result, result.error);
            this.toast.error(result.error.message);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === "succeeded" || 
            (result.paymentIntent.capture_method === 'manual' && result.paymentIntent.status === 'requires_capture')) {
              // Show a success message to your customer
              // There's a risk of the customer closing the window before callback
              // execution. Set up a webhook or plugin to listen for the
              // payment_intent.succeeded event that handles any business critical
              // post-payment actions.
              this.showThankYouModal();

              const params = {};
              params["model_name"] = this.details['year_make_model'];
              params["year"] = this.details['year'];
              params["color"] = this.details['exterior_color'];
              params["Title"] = this.details['Title'];
              params["miles"] = this.details['miles'];
              params["bid_date"] = this.details['bid_final_date'];
              params["finance_total_costs"] = this.details['finance_total_costs'];
              params["vin"] = this.details['id'];
              params["fair_bid"] = Math.round(this.details['fair_bid'] * 0.1);
              params["date"] = this.details['date'];
              params["customer_name"] = name;
              params["id"] = this.$store.state.auth.user.id;
              params["email"] = this.$store.state.auth.user.email;
              params["phone"] = this.$store.state.auth.user.phone;
              params["zip_code"] = this.$store.state.auth.user.zip_code;

              this.$store.dispatch("paymentStatus", params).then(
                  (value) => {
                    console.log(value);
                    this.isThankYouModalVisible = true;
                  },
                  (error) => {
                    this.toast.error("Sorry! Something went wrong");
                    console.log(error);
                  }
              );

              const data = {};
              data["user_id"] = this.$store.state.auth.user.id;
              data["email"] = this.$store.state.auth.user.email;
              data["bid_id"] = this.$store.state.card.paymentIntent.metadata.bid_id;


              this.$store.dispatch("successPaymentMail", data).then(
                  (value) => {
                    console.log(value);
                  },
                  (error) => {
                    let message = "Sorry! Something went wrong";
                    if (error.response) {
                      // The request was made and the server responded with a status code
                      // that falls out of the range of 2xx
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                      message = error.response.data.message;
                    } else if (error.request) {
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log("Error", error.message);
                      message = error.message;
                    }
                    this.toast.error(message);
                    console.log(error);
                  }
              );

              this.$store.dispatch("paymentInvoice", params).then(
                  (value) => {
                    console.log(value);
                  },
                  (error) => {
                    let message = "Sorry! Something went wrong";
                    if (error.response) {
                      // The request was made and the server responded with a status code
                      // that falls out of the range of 2xx
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                      message = error.response.data.message;
                    } else if (error.request) {
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log("Error", error.message);
                      message = error.message;
                    }
                    this.toast.error(message);
                    console.log(error);
                  }
              );


            }
          }
        });
      // const { token, error } = await this.$stripe.createToken(this.cardNumber);
      // if (error) {
      //   document.getElementById("card-error").innerHTML = error.message;
      //   return;
      // }
      // console.log(token);
    },
  },
};
</script>
<style>
</style>