<template>
<div>
  <div v-if="deal">
    <TopBar />
    <NavBarLogoOnly class="lg:block hidden" />
    <CardDetailsDesktopSection v-if="toggle" class="lg:block hidden" />

    <div
      v-if="!showCardDetails"
      class="w-100 block lg:hidden relative min-h-screen"
    >
      <div class="p-5 border-b flex flex-row items-center">
        <a href="#" @click.prevent="gotoDetails" class="flex mr-3">
          <i class="icon back regular"></i>
        </a>
        <h1>Checkout</h1>
      </div>
      <div class="w-full flex flex-col px-3 py-5">
        <div class="flex flex-row items-center w-full">
          <img class="h-20 w-28 object-cover" :src="deal.image" />
          <div class="flex flex-col pl-3 flex-grow min-w-0">
            <p
              class="
                text-sm
                whitespace-nowrap
                overflow-hidden overflow-ellipsis
                font-semibold
                w-full
              "
            >
              {{ deal.title }}
            </p>
            <ul
              class="
                flex flex-row
                list-none
                text-black-lighter text-xs
                mt-2
                mb-1
              "
            >
              <li class="mr-5 flex flex-row items-center">
                <i
                  class="
                    rounded-full
                    w-1
                    h-1
                    mr-1
                    bg-black-lighter
                    inline-block
                  "
                ></i
                >{{ deal.type }}
              </li>
              <li class="flex flex-row items-center">
                <i
                  class="
                    rounded-full
                    w-1
                    h-1
                    mr-1
                    bg-black-lighter
                    inline-block
                  "
                ></i
                >{{ deal.miles }} Miles
              </li>
            </ul>
<!--            <p class="text-xs text-black-lighter">-->
<!--              <i-->
<!--                class="rounded-full w-1 h-1 mr-1 bg-black-lighter inline-block"-->
<!--              ></i-->
<!--              >Current Purchase Price ${{ deal.currentBid.toLocaleString() }} USD-->
<!--            </p>-->
          </div>
        </div>
        <div
          class="
            flex flex-row
            justify-between
            items-center
            border
            px-3
            py-2
            mt-5
            bg-gray-light
            w-full
          "
        >
          <p class="text-black-lighter text-sm">Your Purchase Price</p>
          <p class="text-black-light text-base">
            ${{ bidValue.toLocaleString() }}
          </p>
        </div>

        <div
          class="
            flex flex-row
            justify-between
            items-center
            border
            px-3
            py-2
            mt-3
            bg-gray-light
            w-full
          "
        >
          <p class="text-black-lighter text-sm">10% of Your Purchase Price</p>
          <p class="text-blue text-base">${{ bidBookValue() }}</p>
        </div>

        <DefaultAccordian class="mt-3" title="Terms and Refunds">
<!--          <p class="text-black-lighter text-xs leading-5 px-3 pb-3">-->
<!--            To make you to feel our auction process smooth and hassle free,-->
<!--            FairPY only blocks amount of 10% of your initial Bid to process the-->
<!--            auction on behalf of you. FairPY accepts the pending 90% of the Bid-->
<!--            amount when and only the vehicle won the bid and ready for your-->
<!--            delivery. This 10% of your payment will be refunded only when the-->
<!--            auction is lost or the bidding doesnt happen for any external-->
<!--            reasons. The 10% will not be refunded at anycase even like if you-->
<!--            cancel the Bid earlier or return the vehicle after delivery.-->
<!--          </p>-->

          <p class="text-black-lighter text-xs leading-5 px-3 pb-3">
            To make you to feel our auction process smooth and hassle free,
            FairPY only blocks amount of 10% of your initial Bid to process the
            auction on behalf of you. FairPY accepts the pending 90% of the Bid
            amount when and only the vehicle won the bid and ready for your
            delivery. This 10% of your payment will be refunded only when the
            auction is lost or the bidding doesnt happen for any external
            reasons. The 10% will not be refunded at anycase even like if you
            cancel the Bid earlier or return the vehicle after delivery.
          </p>
        </DefaultAccordian>

        <div class="border flex-grow mt-6 p-3 flex flex-col mb-24">
          <h3 class="text-lg font-medium pb-3 mb-3 border-b">Summary</h3>
          <div class="flex flex-col w-full text-black-lighter flex-grow">
            <div class="flex justify-between items-center text-sm mb-4 mt-4">
              <p>10% of Purchase Price</p>
              <p class="font-semibold text-black-light">
                ${{ bidBookValue() }}
              </p>
            </div>
            <div class="flex justify-between items-center text-sm mb-8">
              <p class="text-green-700">Promo Code Applied</p>
              <p class="font-semibold text-green-700">- ${{this.discountAmount }}</p>
            </div>
            <div class="flex justify-between items-center text-sm mb-8">
              <p>Other Charges</p>
              <p class="font-semibold text-black-light">$0.00</p>
            </div>
            <hr />
            <div class="flex justify-between items-center text-sm mb-4 mt-8">
              <p class="text-black-light">Total Hold Amount</p>
              <p class="font-semibold text-black-light text-sm">
                ${{ this.afterDiscountAmount }}
              </p>
            </div>
          </div>
        </div>

        <div
          class="
            p-3
            fixed
            bottom-0
            bg-white
            shadow-top-shadow
            left-0
            right-0
            flex-grow
            justify-end
            w-full
          "
        >
          <button
            @click.prevent="showCardDetailsScreen"
            class="bg-blue uppercase text-white p-4 py-3 w-full"
          >
            ADD CARD DETAILS
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="showCardDetails && !toggle"
      class="w-100 block lg:hidden relative min-h-screen"
    >
      <div class="p-5 border-b flex flex-row items-center">
        <a href="#" @click.prevent="backToSummary" class="flex mr-3"
          ><i class="icon back regular"></i
        ></a>
        <h1>Enter Card Details</h1>
      </div>
      <div class="w-full flex flex-col px-3 py-5">
        <CreditCardForm class="mb-24" />

        <div
          class="
            p-3
            fixed
            bottom-0
            bg-white
            shadow-top-shadow
            left-0
            right-0
            flex-grow
            justify-end
            w-full
          "
        >
          <button
            @click.prevent="pay"
            class="bg-blue uppercase text-white p-4 py-3 w-full"
          >
            Pay Now And Block
          </button>
        </div>
      </div>
    </div>

    <Footer class="lg:block hidden" />
  </div>

  <Modal
    :showModalBg="false"
    bodyClass="lg:w-md-modal w-screen lg:h-auto justify-center m-auto p-5 min-h-screen lg:min-h-0"
    v-show="isThankYouModalVisible"
    @close="closeModalAndGotoHome"
  >
    <template v-slot:header>
      <div></div>
    </template>

    <template v-slot:body>
      <div class="flex justify-center relative w-full">
        <div
          class="
            w-full
            flex flex-col
            justify-center
            items-center
            text-center
            lg:px-12
            py-12
            px-6
            relative
          "
        >
          <div class="lg:w-2/3 w-10/12 flex flex-col items-center mt-8">
            <img class="lg:w-36" src="/images/thankyou-modal-icon.png" />
          </div>
          <h1
            class="
              text-3xl
              font-display
              mt-8
              mb-6
              text-center
              lg:px-12 lg:mx-12
              px-4
              mx-4
            "
          >
            Thank You
          </h1>
          <p class="text-sm text-gray-medium lg:px-4 px-0 mb-6">
            Your Bid has been Placed Successfully.
          </p>
          <button
            @click.prevent="closeModalAndGotoHome"
            class="bg-blue uppercase text-white p-3 px-6 block"
          >
            Continue
          </button>
        </div>
      </div>
    </template>
  </Modal>
  </div>
</template>
<script>
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";
import CardDetailsDesktopSection from "../components/CardDetailsDesktopSection.vue";
import CreditCardForm from "../components/CreditCardForm.vue";
import DefaultAccordian from "../components/DefaultAccordian.vue";
import Footer from "../components/Footer.vue";
import Modal from "../components/Modal.vue";
import NavBarLogoOnly from "../components/NavBarLogoOnly.vue";
import TopBar from "../components/TopBar.vue";

export default {
  name: "Card Details",
  components: {
    TopBar,
    NavBarLogoOnly,
    Footer,
    DefaultAccordian,
    CreditCardForm,
    CardDetailsDesktopSection,
    Modal,
  },
  computed: {
    ...mapGetters({
      deal: "selectedDeal",
      bidValue: "bidValue",
    }),
  },
  watch: {
    deal(value) {
      console.log(value);
      return  this.details = value;
    },
  },
  methods: {
    getPromoCode(){
      const promoInfo = {
        customer_id: this.$store.state.auth.user.id,
        vin: this.deal.id,
      };
      console.log(promoInfo);
      this.$store.dispatch("getPromoCode", promoInfo).then(
          (value) => {
            console.log('get response -----');
            console.log(this.bidValue * 0.1);
            let tenPercentage = Math.round(this.bidValue * 0.1)
            this.discountAmount = value.data.data.amount;
            this.afterDiscountAmount = tenPercentage - value.data.data.amount;
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },
    bidBookValue() {
      const value = Math.round(this.bidValue * 0.1);
      return value.toLocaleString();
    },
    showCardDetailsScreen() {
      this.showCardDetails = true;
    },
    backToSummary() {
      this.showCardDetails = false;
    },
    gotoDetails() {
      this.$router.back();
    },
    setup() {
      const toast = useToast();
      return {
        toast,
        details: ''
      };
    },
    showThankYouModal() {
      this.isThankYouModalVisible = true;
      document.body.classList.add("overflow-hidden");
      // this.updateBidToServer();
    },
    closeModal() {
      this.isThankYouModalVisible = false;
      document.body.classList.remove("overflow-hidden");
    },
    closeModalAndGotoHome() {
      this.closeModal();
      this.$router.push({
        name: "Home",
      });
    },
    myEventHandler() {
      this.toggle = screen.width > 768;
    },
    async pay() {
      console.log(this.$store.state.card.cardNumber);
      console.log(this.$store.state.card.paymentIntent);

      this.$store.dispatch("setIsLoading", true);

      let name = this.$store.state.auth.user.first_name;
      name += " ";
      name += this.$store.state.auth.user.last_name;

      this.$store.state.card.stripe
        .confirmCardPayment(
          this.$store.state.card.paymentIntent.client_secret,
          {
            payment_method: {
              card: this.$store.state.card.cardNumber,
              billing_details: {
                name: name,
              },
            },
          }
        )
        .then((result) => {
          this.$store.dispatch("setIsLoading", false);
          if (result.error) {
            // Show error to your customer (for example, insufficient funds)
            console.log(result, result.error);
            this.toast.error(result.error.message);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === "succeeded" ||
            (result.paymentIntent.capture_method === 'manual' && result.paymentIntent.status === 'requires_capture')) {
              // Show a success message to your customer
              // There's a risk of the customer closing the window before callback
              // execution. Set up a webhook or plugin to listen for the
              // payment_intent.succeeded event that handles any business critical
              // post-payment actions.
              this.showThankYouModal();

              const params = {};
              params["model_name"] = this.details['year_make_model'];
              params["year"] = this.details['year'];
              params["color"] = this.details['exterior_color'];
              params["Title"] = this.details['Title'];
              params["miles"] = this.details['miles'];
              params["bid_date"] = this.details['bid_final_date'];
              params["finance_total_costs"] = this.details['finance_total_costs'];
              params["vin"] = this.details['id'];
              params["fair_bid"] = Math.round(this.details['fair_bid'] * 0.1);
              params["date"] = this.details['date'];
              params["customer_name"] = name;
              params["id"] = this.$store.state.auth.user.id;
              params["email"] = this.$store.state.auth.user.email;
              params["phone"] = this.$store.state.auth.user.phone;
              params["zip_code"] = this.$store.state.auth.user.zip_code;

              this.$store.dispatch("paymentStatus", params).then(
                  (value) => {
                    console.log(value);
                    this.isThankYouModalVisible = true;
                  },
                  (error) => {
                    this.toast.error("Sorry! Something went wrong");
                    console.log(error);
                  }
              );

              const data = {};
              data["user_id"] = this.$store.state.auth.user.id;
              data["email"] = this.$store.state.auth.user.email;
              data["bid_id"] = this.$store.state.card.paymentIntent.metadata.bid_id;

              this.$store.dispatch("successPaymentMail", data).then(
                  (value) => {
                    console.log(value);
                  },
                  (error) => {
                    let message = "Sorry! Something went wrong";
                    if (error.response) {
                      // The request was made and the server responded with a status code
                      // that falls out of the range of 2xx
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                      message = error.response.data.message;
                    } else if (error.request) {
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log("Error", error.message);
                      message = error.message;
                    }
                    this.toast.error(message);
                    console.log(error);
                  }
              );


              this.$store.dispatch("paymentInvoice", params).then(
                  (value) => {
                    console.log(value);
                    console.log('payment status');
                  },
                  (error) => {
                    let message = "Sorry! Something went wrong";
                    if (error.response) {
                      // The request was made and the server responded with a status code
                      // that falls out of the range of 2xx
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                      message = error.response.data.message;
                    } else if (error.request) {
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log("Error", error.message);
                      message = error.message;
                    }
                    this.toast.error(message);
                    console.log(error);
                  }
              );


            }
          }
        });
      // const { token, error } = await this.$stripe.createToken(this.cardNumber);
      // if (error) {
      //   document.getElementById("card-error").innerHTML = error.message;
      //   return;
      // }
      // console.log(token);
    },
  },
  mounted() {
    if (!this.deal || !this.bidValue) {
      if (this.$route.params.vin) {
        this.$router.push({
          name: "Details",
          params: {
            vin: this.$route.params.vin,
          },
        });
      } else {
        this.$router.push("/");
      }
    }
    window.addEventListener("resize", this.myEventHandler);
    this.getPromoCode();
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  data() {
    return {
      showCardDetails: false,
      isThankYouModalVisible: false,
      toggle: screen.width > 768,
      discountAmount: '',
      afterDiscountAmount: '',
    };
  },
};
</script>

<style></style>