<template>
  <div>
    <button
      @click="toggleAccordion()"
      class="bg-white flex flex-col w-full items-stretch border"
      :aria-expanded="isOpen"
    >
      <div class="flex flex-row w-full items-center">
        <div class="flex flex-col items-start flex-grow">
          <div
            class="flex flex-row justify-between items-center w-full px-3 py-3"
          >
            <div class="text-left text-sm text-black-lighter rounded-sm">
              {{ title }}
            </div>
            <svg
              class="
                w-3
                transition-all
                duration-200
                transform
                flex-shrink-0
                ml-3
              "
              :class="{
                'rotate-180': isOpen,
                'rotate-0': !isOpen,
              }"
              fill="none"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 10"
              aria-hidden="true"
            >
              <path
                d="M15 1.2l-7 7-7-7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="">
        <p v-show="isOpen" class="text-black-lighter text-sm text-left">
          <slot></slot>
        </p>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    title: String,
    iconClass: String,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
